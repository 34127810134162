require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

$(document).on("turbolinks:load", function(){
  $("form").submit(function() {
    $(this).append($('<input>', { type: 'hidden', name: 'recaptcha_token', value: $("#recaptcha_badge").attr("data-recaptcha-token") }))
    $('.fa-spinner').show()
  })

  $("form").on('ajax:success', function(event) {
    var detail = event.originalEvent.detail[0]
    $('.fa-spinner').hide()
    if (detail["success"]) {
      $(".success").show()
      $("form").hide()
    } else {
      $(".form-group").removeClass("invalid")
      $.each(detail["errors"], function(column_name, message) {
        var x = $("form input[name*="+column_name+"]").parents(".form-group")
        x.addClass("invalid")
        x.find('.error').text(message[0])
      })
      window.getCaptchaToken();
    }
  })
});
